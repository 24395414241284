import axios from 'axios'

// Define the API base domain based on the current environment
let baseAdminDomain = window.location.host.toString()
let baseDomain

switch (baseAdminDomain) {
  case 'admin-dev.hubswitch.com':
    baseDomain = 'https://inventory-api-dev.hubswitch.com'
    break
  case 'admin-beta.hubswitch.com':
    baseDomain = 'https://inventory-api-beta.hubswitch.com'
    break
  case 'admin.hubswitch.com':
    baseDomain = 'https://inventory-api.hubswitch.com'
    break
  case '127.0.0.1:3000':
    baseDomain = 'http://127.0.0.1:3030'
    break
  case 'admin-yok.hubswitch.com':
  case 'admin-ton.hubswitch.com':
  case 'admin-an.hubswitch.com':
    baseDomain = 'https://inventory-api-beta.hubswitch.com'
    break
  default:
    baseDomain = `http://${window.location.hostname}:3030`
}

const apiEndpoint = baseDomain + '/v1'

// Set up Axios instance
const apiInstance = axios.create({
  baseURL: apiEndpoint,
  headers: {
    'Content-Type': 'application/json',
  },
})

// 🔹 **Request Interceptor to Attach Authorization Headers**
apiInstance.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem('api_token')
    const invToken = localStorage.getItem('inv_token')

    if (config.url.includes('admins/token')) {
      config.headers.Authorization = `Bearer ${token}`
    } else {
      config.headers.Authorization = `Bearer ${invToken}`
    }
    return config
  },
  (error) => Promise.reject(error)
)

// 🔹 **Response Interceptor to Handle Token Expiry & Auto-Retry Requests**
apiInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config

    // Check if error is a 401 Unauthorized due to expired token
    if (
      error.response?.status === 401 &&
      error.response?.data?.error?.code === 'E_UNAUTHORIZED_ACCESS' &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true // Prevent infinite loops

      try {
        // 🔹 Refresh the token
        const refreshResponse = await apiInstance.get('admins/token')
        const newToken = refreshResponse.data?.token

        if (newToken) {
          // 🔹 Store the new token in local storage
          localStorage.setItem('inv_token', newToken)

          // 🔹 Update the request headers with the new token
          originalRequest.headers.Authorization = `Bearer ${newToken}`

          // 🔹 Retry the original request with the new token
          return apiInstance(originalRequest)
        }
      } catch (refreshError) {
        return Promise.reject(refreshError)
      }
    }
    return Promise.reject(error)
  }
)

/// 🔹 **Helper Function for API Calls**
const api = {
  get: async (path, params = {}) => {
    try {
      const response = await apiInstance.get(path, { params })
      return { data: response.data }
    } catch (error) {
      return handleApiError(error)
    }
  },

  post: async (path, data) => {
    try {
      const response = await apiInstance.post(path, data)
      return { data: response.data }
    } catch (error) {
      return handleApiError(error)
    }
  },

  put: async (path, data) => {
    try {
      const response = await apiInstance.put(path, data)
      return { data: response.data }
    } catch (error) {
      return handleApiError(error)
    }
  },

  patch: async (path, data) => {
    try {
      const response = await apiInstance.patch(path, data)
      return { data: response.data }
    } catch (error) {
      return handleApiError(error)
    }
  },

  delete: async (path) => {
    try {
      const response = await apiInstance.delete(path)
      return { data: response.data }
    } catch (error) {
      return handleApiError(error)
    }
  },
}

// 🔹 **Error Handler for API Calls**
const handleApiError = (error) => {
  console.error('❌ API Error:', error.response?.status, error.response?.data)
  return {
    status: error.response?.status,
    error: error.response?.data?.error,
  }
}

export default api
