import axios from 'axios'

let baseAdminDomain = window.location.host.toString()
let baseDomain
switch (baseAdminDomain) {
  case 'admin-dev.hubswitch.com':
    baseDomain = 'https://vims-api-dev.hubswitch.com'
    break
  case 'admin-beta.hubswitch.com':
    baseDomain = 'https://vims-api-beta.hubswitch.com'
    break
  case 'admin.hubswitch.com':
    baseDomain = 'https://vims-api.hubswitch.com'
    break
  case '127.0.0.1:3000':
    baseDomain = 'http://127.0.0.1:3332'
    break
  case 'admin-yok.hubswitch.com':
    baseDomain = 'https://vims-api-beta.hubswitch.com'
    break
  case 'admin-ton.hubswitch.com':
    baseDomain = 'https://vims-api-beta.hubswitch.com'
    break
  case 'admin-an.hubswitch.com':
    baseDomain = 'https://vims-api-beta.hubswitch.com'
    break
  case 'admin-vims.hubswitch.com':
    baseDomain = 'https://vims-api-qa.hubswitch.com'
    break
  default:
    baseDomain = 'http://' + window.location.hostname + ':3332'
    break
}
const apiEndpoint = baseDomain + '/v1'

// The token is stored in local storage
const token = localStorage.getItem('api_token')

// Set up Axios instance
export const apiInstance = axios.create({
  baseURL: apiEndpoint,
})

// Add a request interceptor
apiInstance.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ${token}`
    return config
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error)
  }
)

const api = {
  get: async (path, params = {}) => {
    try {
      const response = await apiInstance.get(`${apiEndpoint}/${path}`, {
        params,
      })
      return response.data
    } catch (error) {
      console.error('GET Error:', error.message)
      return null
    }
  },

  post: async (path, data) => {
    try {
      const response = await apiInstance.post(`${apiEndpoint}/${path}`, data)
      return response.data
    } catch (error) {
      console.error('POST Error:', error.message)
      return null
    }
  },

  put: async (path, data) => {
    try {
      const response = await apiInstance.put(`${apiEndpoint}/${path}`, data)
      return response.data
    } catch (error) {
      console.error('PUT Error:', error.message)
      return null
    }
  },

  delete: async (path) => {
    try {
      const response = await apiInstance.delete(`${apiEndpoint}/${path}`)
      return response.data
    } catch (error) {
      console.error('DELETE Error:', error.message)
      return null
    }
  },
}

export default api
