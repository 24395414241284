import { Modal, Button } from 'react-bootstrap'

import { Policy } from '../../utils/Data'
import colors from '../../utils/styles/Colors'

const ConsentModal = ({
  isVisible,
  onClose = () => {},
  onConfirm = () => {},
}) => {
  return (
    <Modal scrollable centered show={isVisible} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title style={{ color: colors.black }}>
          นโยบายความเป็นส่วนตัว
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Policy />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onClose}>ปิด</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ConsentModal
