import { Button } from 'react-bootstrap'

import colors from '../../utils/styles/Colors'

const SuccessLanding = ({ location }) => {
  const params = Object.fromEntries(new URLSearchParams(location.search))
  return (
    <div
      style={{ width: '100vw', height: '100vh', backgroundColor: colors.white }}
      className="d-flex justify-content-center align-items-center"
    >
      <div style={{ textAlign: 'center' }}>
        <div>
          <i
            className="fa-solid fa-triangle-exclamation"
            style={{ fontSize: 60, color: colors.yellow }}
          />
        </div>
        <h2 className="mt-4">เกิดข้อผิดพลาด</h2>
        <div>เกิดข้อผิดพลาดในการเชื่อมต่อกับ Vims กรุณาติดต่อเจ้าหน้าที่</div>
        <div>(Code: {params.code || '-'})</div>
        <Button
          className="mt-3"
          style={{ width: 200 }}
          onClick={() => {
            window.location.href = '/login'
          }}
        >
          เข้าสู่ระบบ
        </Button>
      </div>
    </div>
  )
}

export default SuccessLanding
