import React from 'react'
import { optionValue } from '../utils/Functions'
import { Controller, useFormContext } from 'react-hook-form'

const getValueByStringPath = (obj, path) => {
  const keys = path.split(/[\[\]\.]+/).filter((key) => key.length > 0)
  return keys.reduce(
    (acc, key) => (acc && acc[key] !== undefined ? acc[key] : undefined),
    obj
  )
}

export const FORM_ITEM_TYPES = {
  SELECT: 'select',
  DATEPICKER: 'datepicker',
  BOOTSTRAP: 'bootstrap',
  DRAG_AND_DROP_FILE: 'dragAndDropFile',
  CHECKBOX: 'checkbox',
}

export const FormItem = (props) => {
  const { children, type, name, validator } = props

  const {
    register,
    control,
    setValue,
    formState: { errors },
  } = useFormContext()

  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      switch (type) {
        case FORM_ITEM_TYPES.SELECT: // สำหรับ react-select
          return (
            <Controller
              name={name}
              control={control}
              render={({ field: { value, onChange, ref } }) =>
                React.cloneElement(child, {
                  ref,
                  value: optionValue(value, child.props.options), // จัดการค่าของ react-select
                  onChange: (option) => {
                    setValue(name, option.value, { shouldValidate: true })
                    props.onChange && props.onChange(option)
                  },
                })
              }
            />
          )

        case FORM_ITEM_TYPES.DATEPICKER: // สำหรับ react-datepicker
          return (
            <Controller
              name={name}
              control={control}
              render={({ field: { value, onChange, ref } }) =>
                React.cloneElement(child, {
                  selected: value, // จัดการค่าของ react-datepicker
                  onChange: (date) => {
                    setValue(name, date, { shouldValidate: true })
                    props.onChange && props.onChange(date)
                  },
                  // ref,
                })
              }
            />
          )

        case FORM_ITEM_TYPES.DRAG_AND_DROP_FILE:
          return (
            <Controller
              name={name}
              control={control}
              render={({ field: { value, onChange, ref } }) =>
                React.cloneElement(child, {
                  value,
                  onChange: (files) => {
                    setValue(name, files, { shouldValidate: true })
                    props.onChange && props.onChange(files)
                  },
                  ref,
                })
              }
            />
          )

        // case FORM_ITEM_TYPES.BOOTSTRAP: // สำหรับ React-Bootstrap Form.Control
        //   return React.cloneElement(child, {
        //     ...register(name),
        //     isInvalid: !!getValueByStringPath(errors, name),
        //   })

        case FORM_ITEM_TYPES.BOOTSTRAP: // สำหรับ React-Bootstrap Form.Control
          return (
            <Controller
              name={name}
              control={control}
              render={({ field: { value, onChange, ref } }) =>
                React.cloneElement(child, {
                  value: value || '',
                  onChange: (e) => {
                    if (validator && !validator(e.target.value)) return
                    onChange(e)
                    props.onChange && props.onChange(e)
                  },
                  ref,
                  isInvalid: !!getValueByStringPath(errors, name),
                })
              }
            />
          )

        case FORM_ITEM_TYPES.CHECKBOX: // สำหรับ Checkbox
          return (
            <Controller
              name={name}
              control={control}
              render={({ field: { value, onChange, ref } }) =>
                React.cloneElement(child, {
                  checked: value || false,
                  onChange: (e) => {
                    setValue(name, e.target.checked, { shouldValidate: true })
                    props.onChange && props.onChange(e)
                  },
                  ref,
                })
              }
            />
          )

        default: // Input ทั่วไป
          return React.cloneElement(child, {
            ...register(name),
          })
      }
    }
    return child
  })

  const error = errors ? getValueByStringPath(errors, name)?.message : ''

  return (
    <>
      <div
        style={
          error
            ? { border: '1px solid red', borderRadius: '5px' }
            : { border: 'none' }
        }
      >
        {childrenWithProps}
      </div>
      {error && <div style={{ color: 'red', marginTop: '5px' }}>{error}</div>}
    </>
  )
}
