import { useState, useEffect } from 'react'
import { Form, InputGroup, Button } from 'react-bootstrap'
import { useForm, FormProvider, useWatch } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { ToastContainer, toast } from 'react-toastify'

import { FormItem, FORM_ITEM_TYPES } from '../../components/FormItem'
import vimsApi from '../../utils/VimsApi'
import api from '../../utils/Api'
import ConsentModal from './ConsentModal'
import PolicyModal from './PolicyModal'
import colors from '../../utils/styles/Colors'
import { FullscreenLoading } from '../../components/Modals'

const BUSINESS_TYPES = {
  COMPANY: 'company',
  PERSONAL: 'personal',
}

const schema = yup.object().shape({
  email: yup.string().required('Email is required').email('Email is invalid'),
  password: yup
    .string()
    .required('Password is required')
    .min(6, 'Password must be at least 6 characters'),
  confirm_password: yup
    .string()
    .required('Confirm Password is required')
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
  consent: yup
    .bool()
    .oneOf([true], 'You need to accept the terms and conditions'),
})

const defaultValues = {
  business_type: BUSINESS_TYPES.COMPANY,
  consent: false,
}

const VimsRegister = ({ location }) => {
  const params = Object.fromEntries(new URLSearchParams(location.search))
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [isConsentModalVisible, setIsConsentModalVisible] = useState(false)
  const [isPolicyModalVisible, setIsPolicyModalVisible] = useState(false)
  const [loading, setLoading] = useState(true)

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  })

  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
    reset,
  } = methods

  useEffect(() => {
    const getData = async () => {
      setLoading(true)
      try {
        const response = await vimsApi.post(
          'external/hub-customer-tokens/verify',
          {
            token: params.token,
          }
        )

        if (response.data) {
          const { customer, customerBranch, id } = response.data
          const data = {
            ...defaultValues,
            name: customer.name,
            business_name: customer.name,
            email: customer.email,
            address: customerBranch.address,
            phone: customerBranch.phoneNumber,
            id_card: customerBranch.taxId,
            vim_shop_id: id,
          }
          reset(data)
        }
      } catch (error) {
        console.error(error)
        window.location.replace('/login')
      } finally {
        setLoading(false)
      }
    }

    if (params.token) {
      getData()
    } else {
      window.location.replace('/login')
    }
  }, [params.token])

  const [businessType] = useWatch({
    control,
    name: ['business_type'],
  })

  const checkRegister = async ({ email, password, name }) => {
    try {
      const data = { email, password, name }

      const response = await api.requestPromise(
        'shop/check-register',
        data,
        'POST'
      )

      return response.status === 200
    } catch (err) {
      console.warn(err)
      return false
    }
  }

  const handleRegisterError = (err) => {
    if (err) console.warn(err)
    toast.error('สมัครสมาชิกไม่สำเร็จ')
  }

  const handleRegisterSuccess = async (hubCode, email) => {
    try {
      const response = await vimsApi.post(
        'external/hub-customer-tokens/connect',
        {
          token: params.token,
          customerRefCode: hubCode,
          email,
        }
      )

      if (response.data.success) {
        window.location.replace('/vims/register/success')
      } else {
        window.location.replace(`/vims/register/failed/connect?code=${hubCode}`)
      }
    } catch (err) {
      console.warn(err)
      window.location.replace(`/vims/register/failed/connect?code=${hubCode}`)
    }
  }

  const personalRegister = async (data) => {
    try {
      const payload = {
        email: data.email,
        password: data.password,
        shop_name: data.business_name,
        phone: data.phone,
        role: 'business_owner',
        id_card: data.id_card,
        address: data.address,
        business_type: 'co',
        business_name: data.business_name, //TODO: TBD
        name: data.name,
        vim_shop_id: data.vim_shop_id,
        tax_type: 'vat',
      }

      const response = await api.requestPromise(
        'shop/request-register-personal',
        payload,
        'POST'
      )

      if (response.status === 200) {
        await handleRegisterSuccess(response.data.hub_code, data.email)
      } else {
        handleRegisterError()
      }
    } catch (err) {
      handleRegisterError(err)
    }
  }

  const companyRegister = async (data) => {
    try {
      const payload = {
        email: data.email,
        password: data.password,
        shop_name: data.business_name,
        phone: data.phone,
        role: 'business_owner',
        id_card: data.id_card,
        address: data.address,
        business_type: 'co',
        business_name: data.business_name,
        name: data.name,
        vim_shop_id: data.vim_shop_id,
        tax_type: 'vat',
      }

      const response = await api.requestPromise(
        'shop/request-register',
        payload,
        'POST'
      )

      if (response.status === 200) {
        await handleRegisterSuccess(response.data.hub_code, data.email)
      } else {
        handleRegisterError()
      }
    } catch (err) {
      handleRegisterError(err)
    }
  }

  const onSubmit = async (data) => {
    setLoading(true)
    const isValid = await checkRegister(data)
    if (isValid) {
      if (businessType === BUSINESS_TYPES.COMPANY) await companyRegister(data)
      if (businessType === BUSINESS_TYPES.PERSONAL) await personalRegister(data)
    } else {
      toast.error('อีเมลนี้ถูกใช้แล้ว')
    }
    setLoading(false)
  }

  return (
    <div
      style={{ width: '100vw', height: '100vh' }}
      className="d-flex justify-content-center align-items-center"
    >
      <div
        style={{
          backgroundColor: 'white',
          width: 800,
          borderRadius: 10,
          maxHeight: '90vh',
          maxWidth: '90vw',
          overflowY: 'auto',
        }}
        className="shadow"
      >
        <div
          className="text-center text-primary pt-3 pb-2"
          style={{
            fontSize: 30,
            fontWeight: 'bold',
            position: 'sticky',
            top: 0,
            background: 'white',
            zIndex: 10,
            borderBottom: `1px solid ${colors.gray300}`,
          }}
        >
          สร้างบัญชี Hubswitch
        </div>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)} className="p-4">
            <Form.Group className="mb-4">
              <Form.Label>เลือกประเภทบัญชี</Form.Label>
              <div
                className="d-flex justify-content-center"
                style={{ gap: 10 }}
              >
                <div
                  className={`role-selector cursor-pointer column-center shadow p-3 ${
                    businessType === BUSINESS_TYPES.COMPANY
                      ? 'role-selector-active'
                      : ''
                  }`}
                  style={{
                    flex: 1,
                    borderRadius: 5,
                    height: 'unset',
                    border: `1px solid ${colors.gray300}`,
                  }}
                  onClick={() => {
                    setValue('business_type', BUSINESS_TYPES.COMPANY)
                  }}
                >
                  <i
                    style={{ fontSize: 45, color: colors.primaryDark }}
                    className="fa fa-building mb-2"
                  />
                  <span>บัญชีบริษัท</span>
                </div>
                <div
                  className={`role-selector cursor-pointer column-center shadow p-3 ${
                    businessType === BUSINESS_TYPES.PERSONAL
                      ? 'role-selector-active'
                      : ''
                  }`}
                  style={{
                    flex: 1,
                    borderRadius: 5,
                    height: 'unset',
                    border: `1px solid ${colors.gray300}`,
                  }}
                  onClick={() => {
                    setValue('business_type', BUSINESS_TYPES.PERSONAL)
                  }}
                >
                  <i
                    style={{ fontSize: 45, color: colors.primaryDark }}
                    className="fa fa-user mb-2"
                  />
                  <span>บัญชีบุคคล</span>
                </div>
              </div>
            </Form.Group>
            {businessType === BUSINESS_TYPES.COMPANY && (
              <Form.Group className="mb-2">
                <FormItem name="business_name" type={FORM_ITEM_TYPES.BOOTSTRAP}>
                  <Form.Control
                    type="text"
                    rows={5}
                    placeholder="ชื่อบริษัท"
                    readOnly
                  />
                </FormItem>
              </Form.Group>
            )}
            <Form.Group className="mb-2">
              <FormItem name="name" type={FORM_ITEM_TYPES.BOOTSTRAP}>
                <Form.Control type="text" rows={5} placeholder="ชื่อ-นามสกุล" />
              </FormItem>
            </Form.Group>
            <Form.Group className="mb-2">
              <FormItem name="address" type={FORM_ITEM_TYPES.BOOTSTRAP}>
                <Form.Control
                  type="text"
                  rows={5}
                  placeholder="ที่อยู่"
                  readOnly
                />
              </FormItem>
            </Form.Group>
            <Form.Group className="mb-2">
              <FormItem name="phone" type={FORM_ITEM_TYPES.BOOTSTRAP}>
                <Form.Control
                  type="text"
                  rows={5}
                  placeholder="หมายเลขโทรศัพท์มือถือ"
                  readOnly
                />
              </FormItem>
            </Form.Group>
            <Form.Group className="mb-2">
              <FormItem name="id_card" type={FORM_ITEM_TYPES.BOOTSTRAP}>
                <Form.Control
                  type="text"
                  rows={5}
                  placeholder="เลขที่ผู้เสียภาษี"
                  readOnly
                />
              </FormItem>
            </Form.Group>
            <Form.Group className="mb-2">
              <FormItem name="email" type={FORM_ITEM_TYPES.BOOTSTRAP}>
                <Form.Control type="email" rows={5} placeholder="อีเมล*" />
              </FormItem>
            </Form.Group>
            <Form.Group className="mb-2">
              <FormItem name="password" type={FORM_ITEM_TYPES.BOOTSTRAP}>
                <InputGroup>
                  <Form.Control
                    type={showPassword ? 'text' : 'password'}
                    rows={5}
                    placeholder="รหัสผ่าน*"
                  />
                  <InputGroup.Append>
                    <InputGroup.Text
                      style={{
                        borderTopRightRadius: 5,
                        borderBottomRightRadius: 5,
                      }}
                      onClick={() => setShowPassword(!showPassword)}
                      className="cursor-pointer"
                    >
                      <i
                        className={
                          showPassword ? 'fas fa-eye-slash' : 'fas fa-eye'
                        }
                      />
                    </InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </FormItem>
            </Form.Group>
            <Form.Group className="mb-2">
              <FormItem
                name="confirm_password"
                type={FORM_ITEM_TYPES.BOOTSTRAP}
              >
                <InputGroup>
                  <Form.Control
                    type={showConfirmPassword ? 'text' : 'password'}
                    rows={5}
                    placeholder="ยืนยันรหัสผ่าน*"
                  />
                  <InputGroup.Append>
                    <InputGroup.Text
                      style={{
                        borderTopRightRadius: 5,
                        borderBottomRightRadius: 5,
                      }}
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                      className="cursor-pointer"
                    >
                      <i
                        className={
                          showConfirmPassword
                            ? 'fas fa-eye-slash'
                            : 'fas fa-eye'
                        }
                      />
                    </InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </FormItem>
            </Form.Group>
            <Form.Group className="mb-2 row-center mt-3">
              <Form.Check
                type="checkbox"
                className="mb-2"
                label=""
                onChange={(e) => {
                  setValue('consent', e.target.checked)
                }}
              />
              <Form.Label>
                <div>
                  คุณได้อ่านและยอมรับ{' '}
                  <a
                    className="link"
                    onClick={() => setIsConsentModalVisible(true)}
                  >
                    เงื่อนไขการใช้บริการ
                  </a>{' '}
                  และ{' '}
                  <a
                    className="link"
                    onClick={() => setIsPolicyModalVisible(true)}
                  >
                    นโยบายความเป็นส่วนตัว
                  </a>
                  {errors?.consent?.message && (
                    <div style={{ color: 'red' }}>
                      {errors?.consent?.message}
                    </div>
                  )}
                </div>
              </Form.Label>
            </Form.Group>
            <div className="row-center mt-3">
              <Button type="submit" style={{ width: 200 }}>
                สมัครสมาชิก
              </Button>
            </div>
          </form>
        </FormProvider>
      </div>
      <ConsentModal
        isVisible={isConsentModalVisible}
        onClose={() => setIsConsentModalVisible(false)}
      />
      <PolicyModal
        isVisible={isPolicyModalVisible}
        onClose={() => setIsPolicyModalVisible(false)}
      />
      <ToastContainer autoClose={3000} />
      <FullscreenLoading show={loading} />
    </div>
  )
}

export default VimsRegister
