import { Button } from 'react-bootstrap'

import colors from '../../utils/styles/Colors'

const SuccessLanding = () => {
  return (
    <div
      style={{ width: '100vw', height: '100vh', backgroundColor: colors.white }}
      className="d-flex justify-content-center align-items-center"
    >
      <div style={{ textAlign: 'center' }}>
        <div>
          <i
            className="fa-solid fa-circle-check"
            style={{ fontSize: 60, color: colors.green }}
          />
        </div>
        <h2 className="mt-4">สมัครสมาชิกสำเร็จ</h2>
        <div>ขอบคุณสำหรับการสมัครใช้งาน</div>
        <Button
          className="mt-3"
          style={{ width: 200 }}
          onClick={() => {
            window.location.href = '/login'
          }}
        >
          เข้าสู่ระบบ
        </Button>
      </div>
    </div>
  )
}

export default SuccessLanding
